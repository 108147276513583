import axios from "axios";
import { toast } from "react-toastify";
import { MICRO_API_URL_USER } from "../config/API_URLS";
import { statementError } from "../components/Helper/ToastHelper";
import authHeader from "./auth-header";

class AuthService {
  async forgotPass(payload) {
    const response = await axios
      .post(
        MICRO_API_URL_USER.baseURL + "loginManagement/v1/forgotPassword",
        payload
      )
      .catch((err) => {
        statementError(err?.response?.data?.message);
      });
    return response.data;
  }

  async getOTP(payload) {
    const response = await axios
      .post(MICRO_API_URL_USER.baseURL + "v1/otp", payload)
      .catch((err) => {
        statementError(err?.response?.data?.message);
      });
    return response.data;
  }

  //gemeco url : login
  async login(username, password) {
    const response = await axios
      .post(MICRO_API_URL_USER.baseURL + "loginManagement/v1/login", {
        username: username,
        password: password,
      })
      .catch((err) => {
        statementError(err?.response?.data?.message);
        if (
          err?.response?.data?.message?.toLowerCase() ===
          "password is incorrect !!"
        ) {
          document.getElementById("usernameField").style.border =
            "1px solid red";
          document.getElementById("passField").style.border = "1px solid red";
          document.getElementById("warningCredentials").innerHTML =
            "Invalid Credentials";
          document.getElementById("warningCredentials").style.display = "block";
        } else {
          document.getElementById("usernameField").style.border =
            "1px solid #ced4da";
          document.getElementById("passField").style.border = "none";
          document.getElementById("warningCredentials").innerHTML =
            "Invalid Credentials";
          document.getElementById("warningCredentials").style.display = "none";
        }
      });
    if (response.data.data.token) {
      localStorage.setItem("usertoken", response.data.data.token);
      localStorage.setItem("user", JSON.stringify(response.data.data));
    }
    return response.data;
  }
  async loginSso(session) {
    const response = await axios
      .post(MICRO_API_URL_USER.baseURL + "loginManagement/v1/login",{
        session: session
      })
      .catch((err) => {
        statementError(err?.response?.data?.message);
        if (
          err?.response?.data?.message?.toLowerCase() ===
          "password is incorrect !!"
        ) {
          document.getElementById("usernameField").style.border =
            "1px solid red";
          document.getElementById("passField").style.border = "1px solid red";
          document.getElementById("warningCredentials").innerHTML =
            "Invalid Credentials";
          document.getElementById("warningCredentials").style.display = "block";
        } else {
          document.getElementById("usernameField").style.border =
            "1px solid #ced4da";
          document.getElementById("passField").style.border = "none";
          document.getElementById("warningCredentials").innerHTML =
            "Invalid Credentials";
          document.getElementById("warningCredentials").style.display = "none";
        }
      });
    if (response.data.data.token) {
      localStorage.setItem("usertoken", response.data.data.token);
      localStorage.setItem("user", JSON.stringify(response.data.data));
    }
    return response.data;
  }
  loginViaSso = async (domain) => {
    return await axios
      .get(
        MICRO_API_URL_USER.baseURL + "loginManagement/v1/sso/getIdp",
        {
          headers: authHeader(),
          params:{
            domain: domain,
            app: "Jewel"
          }
        }
      )
      .then((data) => {
        return Promise.resolve(data.data ? data.data : []);
      })
      .catch((err) => Promise.reject(err));
  };
  logout() {
    localStorage.removeItem("usertoken");
    localStorage.removeItem("user");
  }

  //gemeco url : user
  async register(firstname, lastname, username, email, password, company) {
    var payload = {
      firstName: firstname,
      lastName: lastname,
      username: username,
      email: email,
      password: password,
      company: company,
    };
    const response = await axios
      .post(MICRO_API_URL_USER.baseURL + "userManagement/v2/user", payload, {
        params: {
          type: "new",
        },
      })
      .catch((err) => {
        statementError(err.response.data.message);
      });
    return response.data;
  }
}

export default new AuthService();