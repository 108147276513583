import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  SET_USER_AVATAR,
} from "./type.js";

import AuthService from "../services/auth.service";
// import ReactGA from "react-ga";

export const register =
  (firstname, lastname, username, email, password, company) => (dispatch) => {
    return AuthService.register(
      firstname,
      lastname,
      username,
      email,
      password,
      company
    ).then(
      (response) => {
        dispatch({
          type: REGISTER_SUCCESS,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: response.data.message,
        });
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch({
          type: REGISTER_FAIL,
        });
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        return Promise.reject();
      }
    );
  };
export const login = (username, password) => (dispatch) => {
  return AuthService.login(username, password).then(
    (data) => {
      if (data.message.toLowerCase() === "account verification required!") {
        return Promise.reject(data);
      }
      if (document.location.href.includes("https://jewel.gemecosystem.com")) {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag("set", { user_id: data?.data?.username });
      }
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data.data.token, userProfile: data.data },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        error?.response?.data?.message || error?.message || error?.toString();
      dispatch({
        type: LOGIN_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject(error);
    }
  );
};
export const logout = () => (dispatch) => {
  AuthService.logout();
  dispatch({
    type: LOGOUT,
  });
};

export const loginViaSsoFn = (session)=>(dispatch)=>{
  return AuthService.loginSso(session).then(
    (data) => {
      if (data.message.toLowerCase() === "account verification required!") {
        return Promise.reject(data);
      }
      if (
        document.location.href.includes("https://agilecrafter.gemecosystem.com")
      ) {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag("set", { user_id: data?.data?.username });
      }
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data.data.token, userProfile: data.data },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: LOGIN_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
        });

      return Promise.reject(error);
    }
  );
}

export const setUserAvatar = (avatarUrl) => (dispatch) => {
  dispatch({
    type: SET_USER_AVATAR,
    payload:avatarUrl
  });
};


