export const CreateTimelineTC = (val) => {
    let value = val?.props?.val ? val.props.val : null;
    if (!value) {
        return "-";
    }

    const data = Object.keys(value).map((key) => (
        <div>
            {(value[key] !== 0 && key.toLowerCase() !== "total") ||
                key.toLowerCase() === "pass" ||
                key.toLowerCase() === "fail" ? (
                <>
                    <div className="row d-flex my-1">
                        <div className="col-8 badgeStatusTimelineCol pe-0">
                            <span
                                className={`me-2 badgeStatusTimeline ${key.toLowerCase()}-btn`}
                            ></span>
                            <span className="tcKey">
                                {key.at(0).toUpperCase() +
                                    key.substring(1, key.length).toLowerCase()}
                            </span>
                        </div>
                        <div className="col-4 badgeStatusTimelineCol tcValue">
                            {value?.[key]}
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    ));

    return data;
};